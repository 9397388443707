<template>
  <ModalDealInfo @close="showInfoModal = false" :deal_id="deal.id" :show="showInfoModal"></ModalDealInfo>
  <div class="row w-100 m-0 text-start">
    <div class="col-12">
      <h2>Office Management</h2>
    </div>

    <div class="col-12">
      <Toolbar>
        <template #start>
          <button class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseFilters" type="button">
            <i class="fas fa-filter me-2"></i>Filters
          </button>
        </template>
      </Toolbar>
    </div>

    <div class="col-12">
      <div class="collapse show" id="collapseFilters">
        <DealFilters></DealFilters>
      </div>
    </div>

    <div class="col-12 mt-3">
      <DataTable
        :value="deals"
        responsiveLayout="scroll"
        removableSort
        :loading="loading"
        v-model:filters="filters"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="numRows"
        :resizableColumns="true"
        columnResizeMode="expand"
        ref="dt"
      >
        <template #header>
          <div class="row justify-content-between">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <p class="text-start m-0 me-3">Show</p>
                <select class="form-select" v-model="numRows" style="width: 80px">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
            </div>
          </div>
        </template>

        <Column header="Property" :sortable="true" sortField="property.name" filterField="property.name">
          <template #body="{ data }">
            {{ data.property.name }}
          </template>
        </Column>

        <Column header="Agent" :sortable="true" sortField="agent.name" filterField="agent.name">
          <template #body="{ data }">
            {{ `${data.agent.name} ${data.agent.last_name}` }}
          </template>
        </Column>

        <Column header="Team" :sortable="true" sortField="team.name" filterField="team.name">
          <template #body="{ data }">
            {{ data.team.name }}
          </template>
        </Column>

        <Column header="Client" :sortable="true" sortField="client.name" filterField="client.name">
          <template #body="{ data }">
            {{ `${data.client.name} ${data.client.last_name}` }}
          </template>
        </Column>

        <Column field="sale_price" header="Sale Price" :sortable="true"></Column>

        <Column header="Actions">
          <template #body="{ data }">
            <router-link :to="`/office-managment/edit-deal/${data.id}`" class="btn">
              <i class="pi pi-pencil"></i>
            </router-link>
            <button class="btn" @click="showDeal(data)"><i class="pi pi-eye"></i></button>
            <button class="btn" @click="exportPDF(data)"><i class="pi pi-file-pdf"></i></button>
          </template>
        </Column>

        <template #footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="exportCSV">Export CSV</button>
          </div>
        </template>

        <template #loading> <p class="text-center">Loading records, please wait...</p> </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'

import ModalDealInfo from '../../components/modals/ModalDealInfo.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Toolbar from 'primevue/toolbar'

import DealService from '../../services/deal.service'
import { useDatatable } from '../../hooks/useDatatable'

import DealFilters from '../../components/DealFilters.vue'

export default {
  components: {
    DataTable,
    Column,
    ModalDealInfo,
    DealFilters,
    Toolbar,
  },
  setup() {
    const store = useStore()
    const deals = computed(() => store.getters['deals/filteredDeals'])
    const deal = ref({})
    const showInfoModal = ref(false)

    const { loading, filters, editingRows, numRows, dt, exportCSV } = useDatatable()

    onMounted(async () => {
      store.commit('deals/setFilter', null)
      loading.value = true
      await store.dispatch('deals/fetchDeals')
      loading.value = false
    })

    const showDeal = (data) => {
      showInfoModal.value = true
      deal.value = data
    }

    const exportPDF = (data) => {
      DealService.downloadDealPdf(data)
    }

    return {
      deals,
      deal,
      loading,
      filters,
      editingRows,
      numRows,
      dt,
      exportCSV,
      showDeal,
      showInfoModal,
      exportPDF,
    }
  },
}
</script>

