<template>
  <Dialog
    header=" "
    v-model:visible="show"
    :maximizable="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '95vw' }"
    @hide="$emit('close')"
  >
    <div v-if="loading" class="text-center"><i class="pi pi-spin pi-spinner"></i></div>

    <div class="row mt-3">
      <div class="col-6">
        <Panel header="Property" :toggleable="true">
          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Name:</p>
            <p class="m-0">{{ deal.property?.name }}</p>
          </div>

          <div class="d-flex">
            <p class="fw-bold me-2 m-0">State:</p>
            <p class="m-0">{{ deal.property?.state.name }}</p>
          </div>

          <div class="d-flex">
            <p class="fw-bold me-2 m-0">City:</p>
            <p class="m-0">{{ deal.property?.city }}</p>
          </div>

          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Address:</p>
            <p class="m-0">{{ deal.property?.address }}</p>
          </div>

          <div class="d-flex">
            <p class="fw-bold me-2 m-0">MLS:</p>
            <p class="m-0">{{ deal.property?.mls }}</p>
          </div>

          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Description:</p>
            <p class="m-0">{{ deal.property?.description }}</p>
          </div>
        </Panel>
      </div>

      <div class="col-6">
        <Panel header="Client" :toggleable="true">
          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Name:</p>
            <p class="m-0">{{ `${deal.client?.name} ${deal.client?.last_name}` }}</p>
          </div>
          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Email:</p>
            <p class="m-0">{{ deal.client?.email }}</p>
          </div>
          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Address:</p>
            <p class="m-0">{{ deal.client?.address }}</p>
          </div>
          <div class="d-flex">
            <p class="fw-bold me-2 m-0">Phone:</p>
            <p class="m-0">{{ deal.client?.phone }}</p>
          </div>
        </Panel>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <Panel header="Deal Summary" :toggleable="true">
          <div class="row">
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Created:</p>
              <p class="m-0">{{ formatDate(deal.created_at) }}</p>
            </div>
            <div class="col-3">
              <p class="fw-bold m-0">Status:</p>
              <p class="m-0 text-uppercase">{{ deal.deal_status?.name }}</p>
            </div>
            <div class="col-3">
              <p class="fw-bold m-0">Team:</p>
              <p class="m-0">{{ deal.team?.name }}</p>
            </div>
            <div class="col-3">
              <p class="fw-bold m-0">Agent:</p>
              <p class="m-0">{{ `${deal.agent?.name} ${deal.agent?.last_name}` }}</p>
            </div>
            <div class="col-3">
              <p class="fw-bold m-0">Sale Type:</p>
              <p class="m-0">{{ deal.sale_type?.name }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Sale Type:</p>
              <p class="m-0">{{ deal.sale_type?.name }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Escrow Agency:</p>
              <p class="m-0">{{ deal.escrow_agency?.name }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Escrow Number:</p>
              <p class="m-0">{{ deal.escrow_number }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Source:</p>
              <p class="m-0">{{ deal.deal_source?.name }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Notary:</p>
              <p class="m-0">{{ deal.notary?.name }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Attorney:</p>
              <p class="m-0">{{ deal.attorney?.name }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Countdown:</p>
              <p class="m-0">{{ deal.countdown }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">AEO:</p>
              <p class="m-0">{{ formatDate(deal.aeo) }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">EXT:</p>
              <p class="m-0">{{ formatDate(deal.ext) }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">COE:</p>
              <p class="m-0">{{ formatDate(deal.coe) }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Representing:</p>
              <p class="m-0">{{ deal.representing }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Cooperation:</p>
              <p class="m-0">{{ deal.cooperation }}</p>
            </div>
            <div class="col-3 mt-2">
              <p class="fw-bold m-0">Special Conditions:</p>
              <p class="m-0">{{ deal.special_conditions }}</p>
            </div>
          </div>

          <fieldset class="mt-3">
            <legend>$$$$$</legend>
            <div class="row">
              <div class="col-2">
                <p class="fw-bold m-0">Sale Price:</p>
                <p class="m-0 text-uppercase">{{ deal.sale_price }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">% To BR:</p>
                <p class="m-0 text-uppercase">{{ deal.br_percentage }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ To BR:</p>
                <p class="m-0 text-uppercase">{{ deal.br_money }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">% REF FEE:</p>
                <p class="m-0 text-uppercase">{{ deal.ref_fee_percentage?.toFixed(2) }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ REF FEE:</p>
                <p class="m-0 text-uppercase">{{ deal.ref_fee_money?.toFixed(2) }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ REF FEE Total:</p>
                <p class="m-0 text-uppercase">{{ deal.ref_fee_total }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-2">
                <p class="fw-bold m-0">$ Left To Split 1:</p>
                <p class="m-0 text-uppercase">{{ deal.left_to_split_1 }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ To Team:</p>
                <p class="m-0 text-uppercase">{{ deal.team_money }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ Left To BR</p>
                <p class="m-0 text-uppercase">{{ deal.left_to_br }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">% To Closing Cord:</p>
                <p class="m-0 text-uppercase">{{ deal.closing_cord_percentage }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ To Closing Cord:</p>
                <p class="m-0 text-uppercase">{{ deal.closing_cord_money }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ Left To Split 2:</p>
                <p class="m-0 text-uppercase">{{ deal.left_to_split_2 }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-2">
                <p class="fw-bold m-0">% To Agent</p>
                <p class="m-0 text-uppercase">{{ deal.agent_split_percentage }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ To Agent</p>
                <p class="m-0 text-uppercase">{{ deal.agent_split_money }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">% Assistants:</p>
                <p class="m-0 text-uppercase">{{ deal.assistant_fee_percentage?.toFixed(2) }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ Assistants:</p>
                <p class="m-0 text-uppercase">{{ deal.assistant_fee_money?.toFixed(2) }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ Assistants Total:</p>
                <p class="m-0 text-uppercase">{{ deal.assistants_fee_total }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ Left To Split 3:</p>
                <p class="m-0 text-uppercase">{{ deal.left_to_split_3 }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-2">
                <p class="fw-bold m-0">$ Head of {{ deal.team?.name }}:</p>
                <p class="m-0 text-uppercase">{{ deal.head }}</p>
              </div>
              <div class="col-2">
                <p class="fw-bold m-0">$ Agent Final:</p>
                <p class="m-0 text-uppercase">{{ deal.agent_final }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-6" v-if="deal.referrals?.length > 0">
                <Card>
                  <template #title>
                    <span style="font-size: 1rem">Referrals</span>
                  </template>
                  <template #content>
                    <ul class="list-group">
                      <li v-for="referral in deal.referrals" :key="referral.id" class="d-flex list-group-item">
                        <p class="m-0 me-3">{{ referral.name }}:</p>
                        <p class="m-0">${{ referral.fee_money }} - {{ referral.fee_percentage }}%</p>
                      </li>
                    </ul>
                  </template>
                </Card>
              </div>
              <div class="col-6" v-if="deal.assistants?.length > 0">
                <Card>
                  <template #title>
                    <span style="font-size: 1rem">Assistants</span>
                  </template>
                  <template #content>
                    <ul class="list-group">
                      <li v-for="assistant in deal.assistants" :key="assistant.id" class="d-flex list-group-item">
                        <p class="m-0 me-3">{{ assistant.name }}:</p>
                        <p class="m-0">${{ assistant.fee_money }} - {{ assistant.fee_percentage }}%</p>
                      </li>
                    </ul>
                  </template>
                </Card>
              </div>
            </div>
          </fieldset>
        </Panel>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref, watch } from '@vue/runtime-core'

import Panel from 'primevue/panel'
import Card from 'primevue/card'
import Dialog from 'primevue/dialog'

import DealService from '../../services/deal.service'

export default {
  components: { Panel, Card, Dialog },
  props: {
    deal_id: { required: true },
    show: { required: true, type: Boolean },
  },
  setup(props) {
    const deal = ref({})
    const loading = ref(false)

    watch(
      () => props.deal_id,
      async () => {
        loading.value = true
        deal.value = await DealService.fetchDeal(props.deal_id)

        let ref_fee_money = 0
        let ref_fee_percentage = 0
        let assistant_fee_money = 0
        let assistant_fee_percentage = 0
        deal.value.referrals?.forEach((r) => {
          ref_fee_money += Number(r.fee_money)
          ref_fee_percentage += Number(r.fee_percentage)
        })
        deal.value.assistants?.forEach((a) => {
          assistant_fee_money += Number(a.fee_money)
          assistant_fee_percentage += Number(a.fee_percentage)
        })
        deal.value.ref_fee_money = ref_fee_money
        deal.value.ref_fee_percentage = ref_fee_percentage
        deal.value.assistant_fee_money = assistant_fee_money
        deal.value.assistant_fee_percentage = assistant_fee_percentage

        loading.value = false
      }
    )

    const formatDate = (date) => {
      if (!date) return ''
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString('en-US', options)
    }

    return { formatDate, deal, loading }
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  border: 1px solid #b6b6b6;
  legend {
    font-size: 1rem;
  }
}
</style>
